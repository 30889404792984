import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';

// import { DatePicker } from '@mui/x-date-pickers';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Grid, Container, Typography, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// components
import { get, set, update } from 'lodash';
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';


// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const domain = "https://api.searchads.pro";

  // 7 dayes ago
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [endDate, setEndDate] = useState(new Date());

  const [spends, setSpends] = useState(null);
  const [proceeds, setProceeds] = useState(null);
  const [purchases, setPurchases] = useState(null);
  const [expected, setExpected] = useState(null);
  const [profit, setProfit] = useState(null);

  const [apps, setApps] = useState([]);
  const [app, setApp] = useState(null);
  
  const handleAppChange = (event) => {
    setApp(event.target.value);
  };

  const getData = async (url, callback) => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });
    if (response.status === 401) {
      console.log(response);
      window.location.href = '/login';
    } else if (response.status === 200) {
      const data = await response.json();
      callback(data);
    } else {
      console.log(response);
    }
  }

  const getSpends = async () => {
    await getData(
      `${domain}/stats/spends?start_date=${startDate.toISOString().split('T')[0]}&end_date=${endDate.toISOString().split('T')[0]}&adam_ids=${app || ''}`,
      (data) => {
        setSpends(data.timeseries);
      }
    );
  }

  const getProceeds = async () => {
    await getData(
      `${domain}/stats/proceeds?start_date=${startDate.toISOString().split('T')[0]}&end_date=${endDate.toISOString().split('T')[0]}&adam_ids=${app || ''}`,
      (data) => {
        setProceeds(data.timeseries);
      }
    );
  }

  const getPurchases = async () => {
    await getData(
      `${domain}/stats/purchases?start_date=${startDate.toISOString().split('T')[0]}&end_date=${endDate.toISOString().split('T')[0]}&adam_ids=${app || ''}`,
      (data) => {
        setPurchases(data.timeseries);
      }
    );
  }

  const getExpected = async () => {
    await getData(
      `${domain}/stats/expected?start_date=${startDate.toISOString().split('T')[0]}&end_date=${endDate.toISOString().split('T')[0]}&adam_ids=${app || ''}`,
      (data) => {
        setExpected(data.timeseries);
      }
    );
  }

  const getApps = async () => {
    await getData(
      `${domain}/apps/`,
      (data) => {
        setApps(data.apps);
      }
    );
  }

  const formatDateRange = (startDate, endDate) => {
    const start = startDate.toISOString().split('T')[0];
    const end = endDate.toISOString().split('T')[0];
    const endDatePlus = new Date(end);
    const dates = [];
    for (let date = new Date(start); date <= endDatePlus; date.setDate(date.getDate() + 1)) {
      dates.push(date.toISOString().split('T')[0]);
    }
    return dates;
  }

  const formatDataRange = (data) => {
    if (data) {
      const dates = formatDateRange(startDate, endDate);
      const formattedData = dates.map((date) => {
        const item = data.find((item) => item.date === date);
        return item ? item.sum.toFixed(2) : null;
      }
      );
      return formattedData;
    }
    return null;
  }

  useEffect(() => {
    getApps();
  }, []);

  useEffect(() => {
    if (spends && proceeds && purchases && expected) {
      const dates = formatDateRange(startDate, endDate);
      const formattedData = dates.map((date) => {
        const item = spends.find((item) => item.date === date);
        const proceedsItem = proceeds.find((item) => item.date === date);
        if (item && proceedsItem) {
          return {'date': date, 'sum': (proceedsItem.sum - item.sum)};
        }
        return {'date': date, 'sum': 0};
      });
      setProfit(formattedData);
    }
  }, [spends, proceeds, purchases, expected]);


  useEffect(() => {
    getSpends();
    getProceeds();
    getPurchases();
    getExpected();
  }, [startDate, endDate, app]);

  return (
    <>
      <Helmet>
        <title> Dashboard | SearchAds Pro </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Weekly Sales" total={714000} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="New Users" total={1352831} color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Item Orders" total={1723315} color="warning" icon={'ant-design:windows-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid> */}

          <Grid item xs={12} md={6} lg={8}>
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
            <Button onClick={() => {
              setStartDate(new Date(new Date().setDate(new Date().getDate() - 90)));
              setEndDate(new Date());
            }}>Last 90 days</Button>
            <Button onClick={() => {
              setStartDate(new Date(new Date().setDate(new Date().getDate() - 30)));
              setEndDate(new Date());
            }}>Last 30 days</Button>
            <Button onClick={() => {
              setStartDate(new Date(new Date().setDate(new Date().getDate() - 7)));
              setEndDate(new Date());
            }}>Last 7 days</Button>
            <Button onClick={() => {
              setStartDate(new Date(new Date().setDate(new Date().getDate() - 1)));
              setEndDate(new Date());
            }}>Yesterday</Button>
          </Grid>

          <Grid item xs={4} md={4} lg={4}>
            <FormControl fullWidth>
              <Select
                id="demo-simple-select"
                value={app}
                displayEmpty
                onChange={handleAppChange}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                  {apps?.map((item) => <MenuItem value={item.adam_id}>{item.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Profit"
              // subheader="(+43%) than last year"
              // Use startDate and endDate
              chartLabels={formatDateRange(startDate, endDate)}
              chartData={[
                {
                  name: 'Proceeds',
                  type: 'area',
                  fill: 'gradient',
                  data: formatDataRange(proceeds) || [],
                },
                {
                  name: 'Spends',
                  type: 'area',
                  fill: 'gradient',
                  data: formatDataRange(spends) || [],
                },
                {
                  name: 'Purchases',
                  type: 'area',
                  fill: 'gradient',
                  data: formatDataRange(purchases) || [],
                },
                {
                  name: 'Expected',
                  type: 'area',
                  fill: 'gradient',
                  data: formatDataRange(expected) || [],
                },
                {
                  name: 'Profit',
                  type: 'column',
                  fill: 'solid',
                  color: '#00c04b',
                  data: formatDataRange(profit) || [],
                },
                // {
                //   name: 'Team B',
                //   type: 'area',
                //   fill: 'gradient',
                //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                // },
                // {
                //   name: 'Team C',
                //   type: 'line',
                //   fill: 'solid',
                //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                // },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Total by period"
              list={[
                {
                  name: 'Spends',
                  value: spends?.reduce((acc, item) => acc + item.sum, 0).toFixed(2),
                },
                {
                  name: 'Proceeds',
                  value: proceeds?.reduce((acc, item) => acc + item.sum, 0).toFixed(2),
                },
                {
                  name: 'Purchases',
                  value: purchases?.reduce((acc, item) => acc + item.sum, 0).toFixed(2),
                },
                {
                  name: 'Expected',
                  value: expected?.reduce((acc, item) => acc + item.sum, 0).toFixed(2),
                },
                {
                  name: 'Profit',
                  value: profit?.reduce((acc, item) => acc + item.sum, 0).toFixed(2),
                },
              ]}
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
